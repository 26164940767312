/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| UTILITY: WIDTH
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/


/* ===== MAX WIDTHS ===== */

[class^="mxw"] { width: 100%; }

/* static */
.mxw-480 { max-width: 480px; }

.mxw-640 { max-width: 640px; }

.mxw-720 { max-width: 720px; }

.mxw-1080 { max-width: 1080px; }

.mxw-1280 { max-width: 1280px; }

.mxw-1440 { max-width: 1440px; }

.mxw-1600 { max-width: 1600px; }

/* dynamic */
.mxw { max-width: $max-width; }

.mxw-text { max-width: $text-max; }

.mxw-xs { max-width: $xs-width; }

.mxw-sm { max-width: $sm-width; }

.mxw-md { max-width: $md-width; }

.mxw-lg { max-width: $lg-width; }

.mxw-xl { max-width: $xl-width; }


/* ===== WIDTHS ===== */

// full view
.w-full { width: 100vw; }

// 1
.w-100 { width: 100%; }

// 7/8
.w-87 { width: 87.5% }

// 3/4
.w-75 { width: 75%; }

// 2/3
.w-66 { width: 66.666666%; }

// 5/8
.w-62 { width: 62.5% }

// 1/2
.w-50 { width: 50%; }

// 3/8
.w-37 { width: 37.5% }

// 1/3
.w-33 { width: 33.333333%; }

// 1/4
.w-25 { width: 25%; }

// 1/8
.w-12 { width: 12.5% }

@include below(tablet) {
	.mb\: {
		&w-full { width: 100vw; }
		&w-100 { width: 100%; }
		&w-87 { width: 87.5% }
		&w-75 { width: 75%; }
		&w-66 { width: 66.666666%; }
		&w-62 { width: 62.5% }
		&w-50 { width: 50%; }
		&w-37 { width: 37.5% }
		&w-33 { width: 33.333333%; }
		&w-25 { width: 25%; }
		&w-12 { width: 12.5% }
	}
}

@include above(tablet) {
	.dt\: {
		&w-full { width: 100vw; }
		&w-100 { width: 100%; }
		&w-87 { width: 87.5% }
		&w-75 { width: 75%; }
		&w-66 { width: 66.666666%; }
		&w-62 { width: 62.5% }
		&w-50 { width: 50%; }
		&w-37 { width: 37.5% }
		&w-33 { width: 33.333333%; }
		&w-25 { width: 25%; }
		&w-12 { width: 12.5% }
	}
}