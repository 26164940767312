/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| LAYOUT: Utility
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

/* ===== WIDTHS ===== */

// full view
.w-full { width: 100vw; }

// 1
.w-100 { width: 100%; }

// 7/8
.w-87 { width: 87.5% }

// 3/4
.w-75 { width: 75%; }

// 2/3
.w-66 { width: 66.666666%; }

// 5/8
.w-62 { width: 62.5% }

// 1/2
.w-50 { width: 50%; }

// 3/8
.w-37 { width: 37.5% }

// 1/3
.w-33 { width: 33.333333%; }

// 1/4
.w-25 { width: 25%; }

// 1/8
.w-12 { width: 12.5% }



/* ===== MAX WIDTHS ===== */

[class*="max-w"] { width: 100%; }

/* static */
.max-w-480 { max-width: 480px; }

.max-w-640 { max-width: 640px; }

.max-w-720 { max-width: 720px; }

.max-w-1080 { max-width: 1080px; }

.max-w-1280 { max-width: 1280px; }

.max-w-1440 { max-width: 1440px; }

.max-w-1600 { max-width: 1600px; }

/* dynamic */
.max-w { max-width: $max-width; }

.max-w-xs { max-width: $xs-width; }

.max-w-sm { max-width: $sm-width; }

.max-w-md { max-width: $md-width; }

.max-w-lg { max-width: $lg-width; }

.max-w-xl { max-width: $xl-width; }

/* text */
[class*="text-max"] { width: 100%; }

.text-max-sm { max-width: $text-max-sm; }

.text-max { max-width: $text-max; }

.text-max-lg { max-width: $text-max-lg; }



/* ===== ALIGNMENT ===== */

/* align self */
.align-center {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.align-left,
.float-left {
	float: left;
}

.align-right,
.float-right {
	float: right;
}

// this one must be inside a centered container
.align-full,
.force-full {
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	width: 100vw;
	max-width: unset;
}



/* ===== OTHER ===== */

.cf,
.clearfix {
	&::after {
		display: block;
		width: 100%;
		height: 0;
		float: none;
		clear: both;
		content: '';
	}
}

/* for when you want a box to continue outside its parent */
%extension_top {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: unset;
		left: 0;
		transform: translateY(100%);
		content: '';
	}
}

%extension_right {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: unset;
		transform: translateX(100%);
		content: '';
	}
}

%extension_bottom {
	position: relative;

	&::before {
		position: absolute;
		top: unset;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateY(-100%);
		content: '';
	}
}

%extension_left {
	position: relative;

	&::before {
		position: absolute;
		top: 0;
		right: unset;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		content: '';
	}
}



/* ===== WHITESPACE ===== */
/*
|
| Syntax
| [rule]-[direction]-[size] 
|
| size values are found in settings.scss
|
| p = padding
| m = margin
|
| sm = small
| md = medium
| lg = large
|
| t = top
| r = right
| b = bottom
| l = left
|
| v = vertical
| h = horizontal
|
*/

/* === padding === */

/* none */
.p-0 { padding: 0; }

.p-t-0 { padding-top: 0; }

.p-r-0 { padding-right: 0; }

.p-b-0 { padding-bottom: 0; }

.p-l-0 { padding-left: 0; }

.p-h-0 {
	padding-left: 0;
	padding-right: 0;
}

.p-v-0 {
	padding-top: 0;
	padding-bottom: 0;
}

/* dynamic */
.p-sm { padding: $x-space-sm; }

.p-md { padding: $x-space; }

.p-lg { padding: $x-space-lg; }

.p-t { padding-top: $space; }

.p-r { padding-right: $space; }

.p-b { padding-bottom: $space; }

.p-l { padding-left: $space; }

/* surrounding */
.p-1 { padding: $space-1; }

.p-2 { padding: $space-2; }

.p-3 { padding: $space-3; }

.p-4 { padding: $space-4; }

.p-5 { padding: $space-5; }

.p-6 { padding: $space-6; }

.p-7 { padding: $space-7; }

.p-8 { padding: $space-8; }

/* horizontal */
.p-h-1 {
	padding-left: $space-1;
	padding-right: $space-1;
}

.p-h-2 {
	padding-left: $space-2;
	padding-right: $space-2;
}

.p-h-3 {
	padding-left: $space-3;
	padding-right: $space-3;
}

.p-h-4 {
	padding-left: $space-4;
	padding-right: $space-4;
}

.p-h-5 {
	padding-left: $space-5;
	padding-right: $space-5;
}

.p-h-6 {
	padding-left: $space-6;
	padding-right: $space-6;
}

.p-h-7 {
	padding-left: $space-7;
	padding-right: $space-7;
}

.p-h-8 {
	padding-left: $space-8;
	padding-right: $space-8;
}

/* vertical */
.p-v-1 {
	padding-top: $space-1;
	padding-bottom: $space-1;
}

.p-v-2 {
	padding-top: $space-2;
	padding-bottom: $space-2;
}

.p-v-3 {
	padding-top: $space-3;
	padding-bottom: $space-3;
}

.p-v-4 {
	padding-top: $space-4;
	padding-bottom: $space-4;
}

.p-v-5 {
	padding-top: $space-5;
	padding-bottom: $space-5;
}

.p-v-6 {
	padding-top: $space-6;
	padding-bottom: $space-6;
}

.p-v-7 {
	padding-top: $space-7;
	padding-bottom: $space-7;
}

.p-v-8 {
	padding-top: $space-8;
	padding-bottom: $space-8;
}

/* === margin === */

/* none */
.m-0 { margin: 0; }

.m-t-0 { margin-top: 0px; }

.m-r-0 { margin-right: 0px; }

.m-b-0 { margin-bottom: 0px; }

.m-l-0 { margin-left: 0px; }

.m-v-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.m-h-0 {
	margin-right: 0;
	margin-left: 0;
}

/* dynamic */
.m-sm { margin: $x-space-sm; }

.m-md { margin: $x-space; }

.m-lg { margin: $x-space-lg; }

.m-t { margin-top: $space; }

.m-r { margin-right: $space; }

.m-b { margin-bottom: $space; }

.m-l { margin-left: $space; }

/* top */
.m-t-1 { margin-top: $space-1; }

.m-t-2 { margin-top: $space-2; }

.m-t-3 { margin-top: $space-3; }

.m-t-4 { margin-top: $space-4; }

.m-t-5 { margin-top: $space-5; }

.m-t-6 { margin-top: $space-6; }

.m-t-7 { margin-top: $space-7; }

.m-t-8 { margin-top: $space-8; }

/* right */
.m-r-1 { margin-right: $space-1; }

.m-r-2 { margin-right: $space-2; }

.m-r-3 { margin-right: $space-3; }

.m-r-4 { margin-right: $space-4; }

.m-r-5 { margin-right: $space-5; }

.m-r-6 { margin-right: $space-6; }

.m-r-7 { margin-right: $space-7; }

.m-r-8 { margin-right: $space-8; }

/* bottom */
.m-b-1 { margin-bottom: $space-1; }

.m-b-2 { margin-bottom: $space-2; }

.m-b-3 { margin-bottom: $space-3; }

.m-b-4 { margin-bottom: $space-4; }

.m-b-5 { margin-bottom: $space-5; }

.m-b-6 { margin-bottom: $space-6; }

.m-b-7 { margin-bottom: $space-7; }

.m-b-8 { margin-bottom: $space-8; }

/* left */
.m-l-1 { margin-left: $space-1; }

.m-l-2 { margin-left: $space-2; }

.m-l-3 { margin-left: $space-3; }

.m-l-4 { margin-left: $space-4; }

.m-l-5 { margin-left: $space-5; }

.m-l-6 { margin-left: $space-6; }

.m-l-7 { margin-left: $space-7; }

.m-l-8 { margin-left: $space-8; }