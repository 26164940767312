/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Card
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.triple-card-row {
	display: flex;
	flex-flow: row wrap;
	margin: -0.6rem;
	justify-content: center;

	.card {
		padding: 0.6rem;
	}

	@include below( tablet ) {
		justify-content: center;

		.card {
			width: 100%;
			max-width: 520px;
		}
	}

	@include between( tablet, desktop ) {
		.card {
			width: 50%;
		}
	}

	@include above( desktop ) {
		.card {
			width: 33.3333%;
			margin: 20px 0;
		}
	}
}

.card {
	display: flex;
	flex-flow: column;

	&__inner {
		flex: 1;
		display: flex;
		flex-flow: column;
		background-color: $white;

		&.inner-border::after {
			margin: 1rem;
			z-index: 0;
		}
	}

	&__content {
		flex: 1;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		position: relative;
		z-index: 1;
		padding: 2rem 2.4rem 3rem 2.4rem;
		text-align: center;

		.btn {
			align-self: flex-end;
		}
	}

	&__heading {
		font-size: 1.4rem;
	}

	&__text {
		font-size: 0.9rem;
	}
}