/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Hero
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

/* ===== VARIABLES ===== */

$hero-bg:      $primary-light;
$hero-color:   $text-color;

/* ===== LAYOUT ===== */

.hero {
	display: flex;
	position: relative;
	z-index: 1;
	background-color: $hero-bg;
	//
	@include below( mobile ) {
		padding-top: $mb_header_ht;
	}

	@include between( mobile, tablet ) {
		padding-top: $tb_header_ht;
	}

	@include above( tablet ) {
		padding-top: $dt_header_ht;
	}
}

/* ===== COMPONENTS ===== */

// IMAGE
.hero-image-wrap {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;

	&.hero-overlay-none::after {
		display: none;
		background: none;
	}
	&.hero-overlay-light::after {
		background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
	}
	&.hero-overlay::after {
		background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 25%, rgba(0,0,0,0.4) 55%, rgba(0,0,0,0) 100%);
	}
	&.hero-overlay-dark::after {
		background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0) 100%);
	}

	.hero.has-image &::after {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: '';
	}
}

.hero-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.hero.has-image {
	.hero-content {
		color: $primary-light;
	}
	.hero-inner {
		min-height: 20rem;
	}
}



// INNER
.hero-inner {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	width: 100%;

	&.has-page-intro {
		margin-bottom: 2rem;
	}
}
//->
	.hero-content {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $hero-color;
		max-width: 48rem;

		h1 {
			color: inherit;
			margin: 0;
		}

		@include below( mobile ) {
			h1 { font-size: 2.4rem; }
		}

		@include between( mobile, tablet ) {
			h1 { font-size: 2.8rem; }
		}

		@include above( tablet ) {
			h1 { font-size: 3rem; }
		}
	}



/* ===== RESPOSIVENESS ===== */

@include below(tablet) {

}

@include between(tablet, desktop) {

}

@include above(desktop) {

}



/* ===== SPECIAL CASES ===== */

.hero-post {
	display: flex;
	flex-flow: column;
	background-color: transparent;

	.hero-content {
		color: $text-color;
	}
}

/* ===== SPECIAL COMPONENTS ===== */
