.heading-fancy {
	display: inline-flex;
	align-items: center;
	text-align: center;
	font-weight: bold;
	font-size: 2rem;
	/* white-space: nowrap; */
	width: 100%;

	&::before,
	&::after {
		flex: 1;
		height: 2px;
		background-color: $grey;
		content: '';
	}

	&::before { margin-right: 1em; }

	&::after { margin-left: 1em; }
}