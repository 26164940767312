/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| CORE: Forms 
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

form {
	display: block;
	max-width: $text-max;
}

input:not([type="checkbox"]):not([type="submit"]),
select,
textarea {
	display: block;
	width: 100%;
	box-shadow: none;
	border: none;
	outline: none;
	border: 2px solid $form-border;
	border-radius: $border-radius;
	background-color: $form-bg;
	padding: 0.5em 0.8em;
	line-height: 1;
	font-size: $font-size;
	color: $text-color;
	transition: border 300ms ease-in-out,
				background-color 300ms ease-in-out;

	&::placeholder {
		opacity: 0.8;
		color: inherit;
	}

	&:focus,
	&:active {
		border-color: $form-focus-border;
		background-color: $form-focus-bg;
	}
}

textarea {
	position: relative;
	height: 4em;
	height: 12em;
	resize: vertical;
	line-height: 1.4;
	transition: height 300ms ease-out,
				border 300ms ease-in-out,
				background-color 300ms ease-in-out;

	&:focus,
	&:active {
		height: 12em;
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}



input.focus-underline {
	border: none;
	background-color: unset;
	background-repeat: no-repeat;
	background-image: linear-gradient(transparent calc(100% - 3px), darken($accent-alt, 8%) 0),
					  linear-gradient(transparent calc(100% - 3px), darken($white, 2%) 0);
	background-size: 0 100%, 100% 100%;
	transition: background 300ms ease-out;

	&:focus,
	&:active {
		border: none;
		background-color: unset;
		background-size: 100% 100%, 100% 100%;
	}
}