/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Rotator
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.rotator {
	position: relative;

	$self: &;

    &__slide {
		position: absolute;
        right: 0;
		left: 0;
		transform: scale(1.1);
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 500ms ease-in-out,
                    transform 500ms ease-in-out,
                    visibility 0ms ease-in-out 500ms;

        &.is-active {
			transform: scale(1);
            opacity: 1;
            visibility: visible;
            transition: opacity 500ms ease-in-out,
                        transform 500ms ease-in-out,
                        visibility 0ms ease-in-out;
        }
	}

    &__button {
		display: flex;
		position: absolute;
		z-index: 9;
		top: 0;
		bottom: 0;
		width: 25%;
		padding: 0.5em;
		font-size: 1rem;

		&--left {
			justify-content: flex-start;
			left: 0;
			right: unset;
		}
		&--right {
			justify-content: flex-end;
			left: unset;
			right: 0;
		}

		.icon-arrow-left,
		.icon-arrow-right {
			width: 1em;
		}

		@include above(mobile) {
			font-size: 1.2rem;
		}

		@include above(tablet) {
			font-size: 1.6rem;
		}
	}

	/* === Rotator Types === */

	&--images {
		position: relative;
		width: 100%;
		height: 100%;
		padding-top: 56.25%;
		overflow: hidden;

		#{$self}__slide {
			top: 0;
			bottom: 0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	&--lightbox {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 800;
		width: 100%;
		margin: 0 3rem;

		#{$self}__slide {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#{$self}__button {
			&--left {
				left: -3rem;
			}
			&--right {
				right: -3rem;
			}
		}

		// If swipe events work
		// @include below(tablet) {
		// 	margin: 0 2rem;

		// 	#{$self}__button {
		// 		display: none;
		// 	}
		// }
	}
}