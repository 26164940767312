/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Featured Content
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.feat-content {
	display: flex;

	&__image {
		position: relative;
		z-index: -1;
		margin: -1.2rem;
	}

	&__copy {
		max-width: 32rem;
		padding: 2.4rem;
	}

	&__heading {
		font-size: 1.4rem;
	}

	@include below( tablet ) {
		flex-flow: column;
		margin: 20px;

		&__image {
			width: unset !important;
			margin: -22px;
			margin-bottom: 0;
			
			&::after {
				padding-top: 45%;
			} 
		}

		&__copy {
			margin: 0 auto;
			text-align: center;
		}
	}

	@include above( tablet ) {
		&__image {
			margin-right: 0;
			max-width: 20rem;
		}
	}
}