.inline-list {
	display: flex;
	flex-flow: row wrap;

    & > &__item {
		display: block;
	}

	& > &__item + &__item::before {
		display: inline-block;
		margin: 0 0.5em;
		content: "";
	}
	
	&--pipline > &__item + &__item::before {
		display: inline-block;
		vertical-align: baseline;
		width: 1px;
		height: 0.7em;
		background-color: currentColor;
		margin: 0 1em;
		content: "";
	}

	&--middot > &__item + &__item::before {
		display: inline-block;
		vertical-align: baseline;
		margin: 0 1em;
		content: "\00B7";
	}

	@include below( tablet ) {
		& > &__item {
			width: 100%;
			text-align: center;
			line-height: 1.6;
		}

		& > &__item + &__item::before {
			display: none;
		}
	}
}