/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Pagination
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.pagination {
	display: flex;
	font-size: 1.2rem;

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 0.25em;
		border-radius: 50%;
		width: 2em;
		height: 2em;
		background-color: $off-white;
		padding: 0.5em;
		line-height: 1;
		vertical-align: baseline;
		text-align: center;
		font-weight: bold;
		font-size: inherit;
		font-family: $link-font;
		color: $text-color;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		color: $link-color;
		transition: color 300ms ease;

		&:hover,
		&:active {
			color: $link-hover;
		}
	}

	&__active {
		color: $primary;
		cursor: default;
	}

	&__disabled {
		color: $text-light;
		cursor: not-allowed;
	}
	
	.icon-arrow-left,
	.icon-arrow-right {
		width: 100%;
		max-width: 0.8em;
		max-height: 0.8em;
		stroke-width: 6px;
		stroke: currentColor;
	}
	.icon-arrow-left {
		margin-right: 0.2em;
	}
	.icon-arrow-right {
		margin-left: 0.2em;
	}
}