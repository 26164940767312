/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Navigation
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.site-nav {
	display: flex;
	flex-flow: row wrap;
	margin: -0.5em;

    &__link {
        display: block;
		padding: 0.5em 0.7em;
		line-height: 1;
		letter-spacing: 1.5px;
		font-family: $link-font;
		font-weight: normal;
		text-transform: uppercase;
		white-space: nowrap;
        color: inherit;
	}
}

.nav {
	display: flex;
	flex-flow: row wrap;
	margin: -0.5em;

    &__item {
        display: block;
		padding: 0.5em 0.7em;
		line-height: 1;
		letter-spacing: 2px;
		font-family: $link-font;
		font-weight: normal;
		text-transform: uppercase;
		white-space: nowrap;
        color: inherit;
	}
}