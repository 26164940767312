/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| CORE: Body
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

html {
	@include fluid-type(font-size, $min-width, $max-width, $font-min, $font-max);
}

body {
	overflow-x: hidden;
	background-color: white;
	line-height: 1;
	font-size: $font-size;
	font-weight: normal;
	font-family: $copy-font;
	text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	color: $text-color;

	// Firefox only
	scroll-behavior: smooth;

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	// if there is an element that is opening and needs to lock scrolling for the site these classes can help
	&.lock-body,
	&[class*='-open'],
	&[class*='-opening'],
	&[class*='-closing'] {
		overflow: hidden;
	}
}

.scroll-lock {
	overflow: hidden !important;
}

// this sould be the new main body container because of Vue
#app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#header {
	// position: relative;
	// z-index: 3;
}

#container {
	position: relative;
	z-index: 2;
	flex: 1;
	overflow: hidden;
}

#footer {
	position: relative;
	z-index: 1;
}