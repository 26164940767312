/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Wood Selection
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.wood-selection {
	display: flex;
	flex-flow: column;
	align-items: center;

	&__copy {
		text-align: center;
		font-size: 0.8rem;
		margin-bottom: $space-8;
	}

	&__heading {
		display: inline-flex;
		margin-bottom: 1rem;
		width: unset;
		font-size: 1.2rem;
		text-transform: uppercase;

		&.heading-fancy {
			&::before,
			&::after {
				width: 4rem;
			}
		}
	}

	&__types {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: -0.5rem;
	}

	&__item {
		position: relative;
		width: 100%;
		text-align: center;
		padding: 0.5rem;
	}

	&__label {
		display: inline-block;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-size: 0.8rem;
		color: $text-color;
	}

	@include below( mobile ) {
		&__item {
			min-width: 50%;
			max-width: 8rem;
		}

		&__heading {
			&.heading-fancy {
				&::before,
				&::after {
					display: none;
				}
			}
		}
	}

	@include between( mobile, tablet ) {
		&__item {
			min-width: 33.3333%;
			max-width: 8rem;
		}
	}

	@include below( tablet ) {
		&__types {
			flex-wrap: wrap;
		}

		&__label {
			position: absolute;
			top: 90%;
			left: 50%;
			transform: translate( -50%, -100% );
			background-color: $white;
			padding: 0.2rem;
		}
	}

	@include above( tablet ) {
		&__label {
			margin-top: 1rem;
		}
	}
}