/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Page Intro
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.intro {
	position: relative;
	z-index: 99;

	$borderOuter: 1.2rem;
	$padBottom: 3rem;

	&__inner {
		box-shadow: 0px 8px 24px 8px rgba(0,0,0,0.1);
		background-color: $white;
		max-width: 40rem;

		&::before {
			display: block;
			position: absolute;
			top: $borderOuter;
			left: 50%;
			transform: translateX(-50%);
			width: 0; 
			height: 0; 
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 9px solid #ececec;
			content: '';
		}

		&::after {
			margin: $borderOuter;
			margin-bottom: $padBottom;
		}
	}

	&__text {
		text-align: center;
		color: $heading-color;
	}

	&__icon {
		display: block;
		position: absolute;
		z-index: 9;
		top: unset;
		bottom: 0.8rem;
		left: 50%;
		transform: translateX(-50%);
		width: 4.5rem;
		height: 4.5rem;
		background-color: $white;
		padding: 0.5rem;
		color: $grey;
	}

	$self: &;

	.has-page-intro & {
		margin-top: 0;
		padding-top: 0;

		#{$self}__inner {
			margin-top: -2rem;
		}
	}

	@include below( mobile ) {
		&__item {
			min-width: 50%;
			max-width: 8rem;
		}

		&__heading {
			&.heading-fancy {
				&::before,
				&::after {
					display: none;
				}
			}
		}
	}

	@include below( mobile ) {
		&__inner {
			padding: $borderOuter + 1.2rem;
			padding-bottom: $padBottom + $borderOuter + 1.2rem;
		}
	}

	@include between( mobile, tablet ) {
		&__inner {
			padding: $borderOuter + 2rem;
			padding-bottom: $padBottom + $borderOuter + 2rem;
		}
	}

	@include above( tablet ) {
		&__inner {
			padding: $borderOuter + 3rem;
			padding-bottom: $padBottom + $borderOuter + 3rem;
		}
	}
}