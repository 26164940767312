.quote-fancy {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: $heading-color;

	&::before,
	&::after {
		flex-shrink: 0;
		width: 3rem;
		height: 4px;
		background-color: $accent;
		content: '';
	}

	&__icon {
		display: block;
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 3.2rem;
		height: 3.2rem;
		color: $grey;
	}

	&--large {
		font-size: 18px;
		line-height: 30px;

		&::before,
		&::after {
			height: 6px;
		}
	}

	@include below( tablet ) {
		&::before,
		&::after {
			position: absolute;
		}

		&::before {
			left: -1em;
			transform: translate( -100% );
		}

		&::after {
			right: -1em;
			transform: translate( 100% );
		}
	}

	@include above( tablet ) {
		&::before {
			margin-right: 2em;
		}
		&::after {
			margin-left: 2em;
		}
	}
}