/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Split Row
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.split-row {
	display: flex;

	&.inner-border::after {
		z-index: 0;
	}

	&__column {
		width: 100%;
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;
	}

	&__inner {
		/*max-width: 24rem;*/
	}

	@include below( mobile ) {
		&__content {
			padding: 3.6rem 2.4rem;
			background-color: $cream-light;
		}
	}

	@include between( mobile, tablet ) {
		&__content {
			padding: 4.0rem 4.0rem;

		}
	}

	@include below( tablet ) {
		flex-direction: column;

		&__image::after {
			padding-top: 75%;
		}
	}

	@include above( tablet ) {
		&:nth-child( odd ) {
			flex-direction: row;
		}
		&:nth-child( even ) {
			flex-direction: row-reverse;
		}
		&__column {
			max-width: 50%;
		}
		&__image::after {
			padding-top: 75%;
		}
	}

	@include between( tablet, desktop ) {
		&__content {
			padding: 4.0rem 2.4rem;
		}
	}

	@include below( desktop ) {
		&.inner-border::after {
			display: none;
		}
	}

	@include above( desktop ) {
		&.inner-border::after {
			margin: 2.4rem;
		}
		&:nth-child( odd ) {
			&.inner-border::after {
				margin-right: 0;
			}
		}
		&:nth-child( even ) {
			&.inner-border::after {
				margin-left: 0;
			}
		}
		&__content {
			padding: 5.6rem 3.2rem;
		}
		&__inner {
			max-width: 24rem;
		}
	}
}