/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Site Footer
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

/* ===== LAYOUT ===== */

.site-footer {
	position: relative;
	z-index: 0;
    background-color: $white;
	color: $accent;

	.footer-accent {
		position: absolute;
		top: 0;
		bottom: unset;
		transform: translateY(-100%);
		width: 100vw;
	}

    .info-footer {
		position: relative;
		background-color: $primary;
    }

    .bottom-footer {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		position: relative;
    }
}

/* ===== COMPONENTS ===== */
.info-card {
	display: flex;
	background-color: $white;
	color: $text-color;

	&__img {
		position: relative;
	}

	&__content {
		display: flex;
		flex-flow: column;
		padding: 3.2rem 1rem;

		@include above( 640px ) {
			padding: 3.2rem;
		}
	}

	&__heading {
		text-transform: uppercase;
		letter-spacing: 0.2em;
		font-weight: bold;
		font-size: .85rem;
		color: $mustard-dark;
	}

	&__number {
		margin-top: 0.8rem;
		letter-spacing: 0.2em;
		font-weight: bold;
		font-size: 1.2rem;
		color: $heading-color;
	}

	&__text {
		display: block;
		line-height: 22px;
		font-size: 14px;
	}
	&__dbl {
		display: flex;
		flex-direction: column;
		margin-top: 15px;

		@include above( desktop ) {
			flex-direction: row;

		}
	}
	&__email {
		color: $heading-color;

		a {
			display: inline-block;
			color: $heading-color;
			font-size: 16px;
			padding: 20px 0 0 0;
		}
	}
	&__hours {
		display: flex;
		justify-content: space-around;
		line-height: 22px;
		font-size: 14px;
		margin-top: 15px;
		margin-left: 0px;
		padding-left: 0px;

		@include above( tablet ) {
			justify-content: flex-start;
		}

		@include above( desktop ) {
			margin-top: 0;
			margin-left: 15px;
			padding-left: 15px;
			border-left: 2px solid $mustard;
		}
	}
	&__hourcol {
		padding: 0;


		&:first-child {
			padding-right: 10px;
		}

	}

	.social-icons {
		margin: -0.4em;
		font-size: 1.2rem;

		&__link {
			margin: 0.4em;

			svg {
				color: $heading-color !important;
			}
		}
	}

	@include below( tablet ) {
		flex-flow: column;

		&__img .bg-img {
			position: relative;

			&::after {
				padding-top: 56.25%;
			}
		}
		&__content {
			align-items: center;
			text-align: center;
		}
		&__heading {
			font-size: 1.2rem;
		}
		&__number {
			font-size: 1.6rem;
		}
		&__text {
			font-size: 1rem;
		}
	}

	@include above( tablet ) {
		&__half {
			width: 50%;
		}
	}
}

.nav.footer {
	justify-content: center;
	margin: 0 auto;
	color: $mustard-dark;

	@include below( 640px ) {
		flex-flow: column;
		align-items: center;
	}

	.nav__item {
		font-size: 0.75rem;
		padding: 0;
	}
	a {
		display: inline-block;
		padding: 10px 8px;
		transition: color 300ms ease-in-out;
		&:hover,
		&:active {
			color: $primary-dark;
		}
	}
}
.bottom-footer .geo-text {
	justify-content: center;
	line-height: 1.6;

	a {
		color: $mustard-dark;
	}
}
.footer-divide {
	margin: 0;
	color: $mustard;
}

.state-logo {
	display: block;
	margin: 0 auto;
	width: 4rem;
	fill: $mustard;
}

/* ===== RESPONSIVE ===== */

@include below( mobile ) {

}

@include between( mobile, tablet ) {

}

@include above( tablet ) {

}