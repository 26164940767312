/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Transcription
|––––––––––––––––––––––––––––––––––––––––––––––––––
|
| For sections where you want your lists, links and etc. to look nice,
| and so you don't have to backpedal any of the styles where you don't need.
|
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

/* === Headings === */

// Padding top is used so if you want to link to a heading there will be space above it
.heading,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0;
	padding-top: 2em;
	line-height: 1.2;
	font-weight: bold;
	font-family: $heading-font;

	&:first-child {
		margin-top: -0.24em;
		padding-top: 0;
	}

	&:last-child {
		margin-bottom: -0.20em;
		padding-bottom: 0;
	}

	& + p {
		margin-top: 0.5rem;
	}
}

.h1, 
h1.default {
	font-size: 3.2rem;
	color: $heading-color;
}

.h2,
h2.default {
	font-size: 2.4rem;
	color: $heading-color;
}

.h3,
h3.default {
	font-size: 1.6rem;
	color: $heading-color;
}

.h4,
h4.default {
	font-size: 1.2rem;
	color: $heading-color;
}

.h5,
h5.default {
	font-size: 1.2rem;
	color: $heading-color-alt;
}

.h6,
h6.default {
	font-size: 1.2rem;
	color: $text-light;
}

// this just keeps headings together by removing space between themselves
.hgroup {
	.h1, .h2, .h3, .h4, .h5, .h6,
	h1, h2, h3, h4, h5, h6 {

		&:not(:last-child) {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	
		&:not(:first-child) {
			margin-top: 0;
			padding-top: 0;
		}
	}
}

/* === Anchor Tags === */

.a,
a:not([class]) {
	color: $link-color;
	transition: color 200ms ease-in-out;

	&:hover,
	&:focus {
		color: $link-hover;
	}
}

.hyperlink,
a.default {
	background-repeat: no-repeat;
	background-image: linear-gradient(transparent calc(100% - 0.2em), $accent-alt 0),
					  linear-gradient(transparent calc(100% - 0.2em), $primary-light 0);
	background-size: 0 100%, 100% 100%;
	color: $text-color;
	transition: background 200ms ease-in-out;

	&:hover {
		color: $text-color;
		background-size: 100% 100%, 100% 100%;
	}
}

/* === List Types === */

.ul,
ul.default {
	margin: 1.6rem 0 0;
	max-width: $text-max;
	line-height: 1.4;
	list-style: none;

	&:first-child {
		margin-top: -0.4em;
	}

	&:last-child {
		margin-bottom: -0.3em;
	}

	li {
		position: relative;
		margin: 0.8em 0 0;
		padding: 0 0 0 2em;
		
		&:first-child {
			margin-top: 0;
		}

		&:before {
			position: absolute;
			top: 0.84em;
			left: 0;
			transform: translateY(-50%);
			width: 0.4em;
			height: 0.4em;
			background-color: $accent;
			content: '';
		}
	}
}

.ol,
ol.default {
	margin: 1.6rem 0 0;
	max-width: $text-max;
	padding: 0 0 0 1.2em;
	line-height: 1.4;
	list-style: none;
	
	&:first-child {
		margin-top: -0.4em;
	}
	&:last-child {
		margin-bottom: -0.3em;
	}

	li {
		margin: 0.8em 0 0;
		padding: 0 0 0 0.8em;
		list-style: decimal outside;

		&:first-child {
			margin-top: 0;
		}
	}
}

/* === Blockquote === */

.blockquote,
blockquote.default {
	margin: 1.6rem 0 0;
	max-width: $text-max;
	border-left: 0.2em solid $accent-alt;
	padding: 0 0 0 1.2em;
	font-size: 1.2rem;

	p {
		line-height: inherit;
		font-size: inherit;
	}
	
	&:first-child {
		margin-top: 0;
	}
}

.cite
cite.default {
	display: block;
	margin: 0;
	padding: 0 0.4rem 0 0;
	text-align: right;
	line-height: 1.2rem;
	font-size: 0.8rem;

	&:before {
		content: '\2014';
	}
}

/* === Putting it all together === */

// This class is often paired with the Statamic Redactor, 
// but it can be used anywhere you want some default styling
.transcription {
	visibility: visible;

	h1, h2, h3, h4, h5, h6 {
		@extend .heading;
	}

	h1 {
		@extend .h1;
	}

	h2 {
		@extend .h2;
	}

	h3 {
		@extend .h3;
	}

	h4 {
		@extend .h4;
	}

	h5 {
		@extend .h5;
	}

	h6 {
		@extend .h6;
	}

	a {
		@extend .hyperlink;
	}

	ul {
		clear: both;
		@extend .ul;
	}

	ol {
		clear: both;
		@extend .ol;
	}

	blockquote {
		clear: both;
		@extend .blockquote;
	}

	cite {
		@extend .cite;
	}

	@include below(640px) {
		.u-double-list { 
			margin: 0 auto;
		}
	}

	@include above(640px) {
		.u-double-list {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			list-style-type: disc;
			margin: -0.5rem auto;
			//width: 80%;

			li {
				margin: 0;
				width: 50%;
				padding: 0.5rem;
				//padding-left: 1.5rem;
				padding-left: 15%;
				line-height: 1;

				&::before {
					left: 20%;
				}
			}

			&::after {
				display: block;
				width: 100%;
				height: 0;
				float: none;
				clear: both;
				content: '';
			}
		}
	}

	&.double-list {
		ul {
			list-style-type: disc;
			margin: 1.1rem -0.5rem -0.5rem;
	
			li {
				margin: 0;
				float: left;
				min-width: 9rem;
				width: 50%;
				padding: 0.5rem;
				line-height: 1;
				font-weight: bold;
				font-size: 0.9rem;
				color: $heading-color;

				&:before {
					display: none;
				}
			}
			
			&::after {
				display: block;
				width: 100%;
				height: 0;
				float: none;
				clear: both;
				content: '';
			}
		}
	}

	strong {
		font-size: 0.9rem;
	}

	em {
		font-size: 0.9rem;
		font-style: normal;
		color: $heading-color;
	}
}

/* ===== Extra Componants ===== */

@import './transcription/heading-fancy.scss';

@import './transcription/quote-fancy.scss';

@import './transcription/text-utility.scss';

@import './transcription/geo-text.scss';

@import './transcription/inline-list.scss';

// @import './transcription/double-list.scss';