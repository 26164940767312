/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| CORE: Buttons 
|––––––––––––––––––––––––––––––––––––––––––––––––––
|
| The button or submit should by default have button styles,
| but once a class is put on them, they should be wiped of styles so you dont have to backpedal.
|
| If you want to put a class on the button but keep the default styles,
| just add the button class to the classes you want.
|
*/

button {
	display: inline-block;
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
	color: $button-color;
	cursor: pointer;
}

.btn,
.button,
[type='submit']:not([class]) {
	display: inline-block;
	flex-shrink: 0;
	min-width: 8rem;
	border: 2px solid $button-border;
	border-radius: $border-radius;
	background-color: $button-bg;
	padding: 0.5rem 1.4rem;
	letter-spacing: 2px;
	line-height: 1;
	font-family: $link-font;
	font-size: 0.8rem;
	font-weight: bold;
	color: $button-color;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	transition: border-color 300ms ease-in-out,
				background-color 300ms ease-in-out,
				color 300ms ease-in-out;
	user-select: none;
	cursor: pointer;

  	&:hover,
  	&:focus {
  	  	outline: none;
  	  	border-color: $button-hover-border;
		background-color: $button-hover-bg;
		color: $button-hover-color;
	}

	&[disabled] {
		opacity: 0.8;
		cursor: not-allowed;

		&:hover,
		&:focus {
			outline: none;
			border-color: $button-border;
			background-color: $button-bg;
			color: $button-color;
		}
	}
}



/* === button types === */

/* square button */
.btn-square {
	border-radius: 0;
}

/* rounded button */
.btn-rounded {
	border-radius: 0.2em;
}
	  
/* pill button */
.btn-pill {
	border-radius: 2.4em;
}

/* border button */
.btn-border {
	border-color: $button-border;
	background: transparent;
	padding: 0.55rem 1.4rem;
	font-size: 0.7rem;
	color: $button-border;
	
	&:hover,
	&:focus {
		border-color: $button-hover-border;
		background-color: $button-hover-bg;
		color: $button-hover-border;
	}
}

/* slim button */
.btn-sm,
.btn-slim {
	border-width: 1px;
	padding: 0.45rem 1.2rem;
	font-size: 0.7rem;
	font-weight: normal;

	&.btn-border {
		padding: 0.5rem 1.2rem;
		font-size: 0.6rem;
	}
}

/* avrg button */
.btn-md,
.btn-avrg {
	border-width: 2px;
	padding: 0.5rem 1.4rem;
	font-size: 0.8rem;

	&.btn-border {
		padding: 0.6rem 1.3rem;
		font-size: 0.7rem;
	}
}

/* phat button */
.btn-lg,
.btn-phat {
	border-width: 2px;
	padding: 0.8rem 2.4rem;
	font-size: 0.8rem;
	font-weight: bold;

	&.btn-border {
		padding: 0.7rem 2.4rem;
		font-size: 0.8rem;
	}
}

/* ===== site specific ===== */