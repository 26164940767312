/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| UTILITY: Whitespace
|––––––––––––––––––––––––––––––––––––––––––––––––––
|
| Syntax
| [rule][direction]-[size] 
|
| size values are found in settings.scss
|
| p = padding
| m = margin
|
| sm = small
| md = medium
| lg = large
|
| t = top
| r = right
| b = bottom
| l = left
|
| v = vertical
| h = horizontal
|
| mb: = below tablet
| dt: = above tablet
|
*/

/* === padding === */

/* none */
.p-0 { padding: 0; }

.pt-0 { padding-top: 0; }

.pr-0 { padding-right: 0; }

.pb-0 { padding-bottom: 0; }

.pl-0 { padding-left: 0; }

.ph-0 {
	padding-left: 0;
	padding-right: 0;
}

.pv-0 {
	padding-top: 0;
	padding-bottom: 0;
}

/* dynamic */
.p { padding: $space; }

.pt { padding-top: $space; }

.pr { padding-right: $space; }

.pb { padding-bottom: $space; }

.pl { padding-left: $space; }

.ph {
	padding-left: $space;
	padding-right: $space;
}

.pv {
	padding-top: $space;
	padding-bottom: $space;
}

/* surrounding */
.p-1 { padding: $space-1; }

.p-2 { padding: $space-2; }

.p-3 { padding: $space-3; }

.p-4 { padding: $space-4; }

.p-5 { padding: $space-5; }

.p-6 { padding: $space-6; }

.p-7 { padding: $space-7; }

.p-8 { padding: $space-8; }

/* horizontal */
.ph-1 {
	padding-left: $space-1;
	padding-right: $space-1;
}

.ph-2 {
	padding-left: $space-2;
	padding-right: $space-2;
}

.ph-3 {
	padding-left: $space-3;
	padding-right: $space-3;
}

.ph-4 {
	padding-left: $space-4;
	padding-right: $space-4;
}

.ph-5 {
	padding-left: $space-5;
	padding-right: $space-5;
}

.ph-6 {
	padding-left: $space-6;
	padding-right: $space-6;
}

.ph-7 {
	padding-left: $space-7;
	padding-right: $space-7;
}

.ph-8 {
	padding-left: $space-8;
	padding-right: $space-8;
}

/* vertical */
.pv-1 {
	padding-top: $space-1;
	padding-bottom: $space-1;
}

.pv-2 {
	padding-top: $space-2;
	padding-bottom: $space-2;
}

.pv-3 {
	padding-top: $space-3;
	padding-bottom: $space-3;
}

.pv-4 {
	padding-top: $space-4;
	padding-bottom: $space-4;
}

.pv-5 {
	padding-top: $space-5;
	padding-bottom: $space-5;
}

.pv-6 {
	padding-top: $space-6;
	padding-bottom: $space-6;
}

.pv-7 {
	padding-top: $space-7;
	padding-bottom: $space-7;
}

.pv-8 {
	padding-top: $space-8;
	padding-bottom: $space-8;
}

/* === margin === */

/* none */
.m-0 { margin: 0; }

.mt-0 { margin-top: 0px; }

.mr-0 { margin-right: 0px; }

.mb-0 { margin-bottom: 0px; }

.ml-0 { margin-left: 0px; }

.mv-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mh-0 {
	margin-right: 0;
	margin-left: 0;
}

/* dynamic */
.m { margin: $space; }

.mt { margin-top: $space; }

.mr { margin-right: $space; }

.mb { margin-bottom: $space; }

.ml { margin-left: $space; }

.mv {
	margin-top: $space;
	margin-bottom: $space;
}

.mh {
	margin-right: $space;
	margin-left: $space;
}

/* top */
.mt-1 { margin-top: $space-1; }

.mt-2 { margin-top: $space-2; }

.mt-3 { margin-top: $space-3; }

.mt-4 { margin-top: $space-4; }

.mt-5 { margin-top: $space-5; }

.mt-6 { margin-top: $space-6; }

.mt-7 { margin-top: $space-7; }

.mt-8 { margin-top: $space-8; }

/* right */
.mr-1 { margin-right: $space-1; }

.mr-2 { margin-right: $space-2; }

.mr-3 { margin-right: $space-3; }

.mr-4 { margin-right: $space-4; }

.mr-5 { margin-right: $space-5; }

.mr-6 { margin-right: $space-6; }

.mr-7 { margin-right: $space-7; }

.mr-8 { margin-right: $space-8; }

/* bottom */
.mb-1 { margin-bottom: $space-1; }

.mb-2 { margin-bottom: $space-2; }

.mb-3 { margin-bottom: $space-3; }

.mb-4 { margin-bottom: $space-4; }

.mb-5 { margin-bottom: $space-5; }

.mb-6 { margin-bottom: $space-6; }

.mb-7 { margin-bottom: $space-7; }

.mb-8 { margin-bottom: $space-8; }

/* left */
.ml-1 { margin-left: $space-1; }

.ml-2 { margin-left: $space-2; }

.ml-3 { margin-left: $space-3; }

.ml-4 { margin-left: $space-4; }

.ml-5 { margin-left: $space-5; }

.ml-6 { margin-left: $space-6; }

.ml-7 { margin-left: $space-7; }

.ml-8 { margin-left: $space-8; }



/* === Below Tablet === */

@include below(tablet) {
	.mb\: {
		/* === padding === */

		/* none */
		&p-0 { padding: 0; }

		&pt-0 { padding-top: 0; }

		&pr-0 { padding-right: 0; }

		&pb-0 { padding-bottom: 0; }

		&pl-0 { padding-left: 0; }

		&ph-0 {
			padding-left: 0;
			padding-right: 0;
		}

		&pv-0 {
			padding-top: 0;
			padding-bottom: 0;
		}

		/* dynamic */
		&p { padding: $space; }

		&pt { padding-top: $space; }

		&pr { padding-right: $space; }

		&pb { padding-bottom: $space; }

		&pl { padding-left: $space; }

		&ph {
			padding-left: $space;
			padding-right: $space;
		}

		&pv {
			padding-top: $space;
			padding-bottom: $space;
		}

		/* surrounding */
		&p-1 { padding: $space-1; }

		&p-2 { padding: $space-2; }

		&p-3 { padding: $space-3; }

		&p-4 { padding: $space-4; }

		&p-5 { padding: $space-5; }

		&p-6 { padding: $space-6; }

		&p-7 { padding: $space-7; }

		&p-8 { padding: $space-8; }

		/* horizontal */
		&ph-1 {
			padding-left: $space-1;
			padding-right: $space-1;
		}

		&ph-2 {
			padding-left: $space-2;
			padding-right: $space-2;
		}

		&ph-3 {
			padding-left: $space-3;
			padding-right: $space-3;
		}

		&ph-4 {
			padding-left: $space-4;
			padding-right: $space-4;
		}

		&ph-5 {
			padding-left: $space-5;
			padding-right: $space-5;
		}

		&ph-6 {
			padding-left: $space-6;
			padding-right: $space-6;
		}

		&ph-7 {
			padding-left: $space-7;
			padding-right: $space-7;
		}

		&ph-8 {
			padding-left: $space-8;
			padding-right: $space-8;
		}

		/* vertical */
		&pv-1 {
			padding-top: $space-1;
			padding-bottom: $space-1;
		}

		&pv-2 {
			padding-top: $space-2;
			padding-bottom: $space-2;
		}

		&pv-3 {
			padding-top: $space-3;
			padding-bottom: $space-3;
		}

		&pv-4 {
			padding-top: $space-4;
			padding-bottom: $space-4;
		}

		&pv-5 {
			padding-top: $space-5;
			padding-bottom: $space-5;
		}

		&pv-6 {
			padding-top: $space-6;
			padding-bottom: $space-6;
		}

		&pv-7 {
			padding-top: $space-7;
			padding-bottom: $space-7;
		}

		&pv-8 {
			padding-top: $space-8;
			padding-bottom: $space-8;
		}

		/* === margin === */

		/* none */
		&m-0 { margin: 0; }

		&mt-0 { margin-top: 0px; }

		&mr-0 { margin-right: 0px; }

		&mb-0 { margin-bottom: 0px; }

		&ml-0 { margin-left: 0px; }

		&mv-0 {
			margin-top: 0;
			margin-bottom: 0;
		}

		&mh-0 {
			margin-right: 0;
			margin-left: 0;
		}

		/* dynamic */
		&m { margin: $space; }

		&mt { margin-top: $space; }

		&mr { margin-right: $space; }

		&mb { margin-bottom: $space; }

		&ml { margin-left: $space; }

		&mv {
			margin-top: $space;
			margin-bottom: $space;
		}

		&mh {
			margin-right: $space;
			margin-left: $space;
		}

		/* top */
		&mt-1 { margin-top: $space-1; }

		&mt-2 { margin-top: $space-2; }

		&mt-3 { margin-top: $space-3; }

		&mt-4 { margin-top: $space-4; }

		&mt-5 { margin-top: $space-5; }

		&mt-6 { margin-top: $space-6; }

		&mt-7 { margin-top: $space-7; }

		&mt-8 { margin-top: $space-8; }

		/* right */
		&mr-1 { margin-right: $space-1; }

		&mr-2 { margin-right: $space-2; }

		&mr-3 { margin-right: $space-3; }

		&mr-4 { margin-right: $space-4; }

		&mr-5 { margin-right: $space-5; }

		&mr-6 { margin-right: $space-6; }

		&mr-7 { margin-right: $space-7; }

		&mr-8 { margin-right: $space-8; }

		/* bottom */
		&mb-1 { margin-bottom: $space-1; }

		&mb-2 { margin-bottom: $space-2; }

		&mb-3 { margin-bottom: $space-3; }

		&mb-4 { margin-bottom: $space-4; }

		&mb-5 { margin-bottom: $space-5; }

		&mb-6 { margin-bottom: $space-6; }

		&mb-7 { margin-bottom: $space-7; }

		&mb-8 { margin-bottom: $space-8; }

		/* left */
		&ml-1 { margin-left: $space-1; }

		&ml-2 { margin-left: $space-2; }

		&ml-3 { margin-left: $space-3; }

		&ml-4 { margin-left: $space-4; }

		&ml-5 { margin-left: $space-5; }

		&ml-6 { margin-left: $space-6; }

		&ml-7 { margin-left: $space-7; }

		&ml-8 { margin-left: $space-8; }
	}
}



/* === Above Tablet === */

@include above(tablet) {
	.dt\: {
		/* === padding === */

		/* none */
		&p-0 { padding: 0; }

		&pt-0 { padding-top: 0; }

		&pr-0 { padding-right: 0; }

		&pb-0 { padding-bottom: 0; }

		&pl-0 { padding-left: 0; }

		&ph-0 {
			padding-left: 0;
			padding-right: 0;
		}

		&pv-0 {
			padding-top: 0;
			padding-bottom: 0;
		}

		/* dynamic */
		&p { padding: $space; }

		&pt { padding-top: $space; }

		&pr { padding-right: $space; }

		&pb { padding-bottom: $space; }

		&pl { padding-left: $space; }

		&ph {
			padding-left: $space;
			padding-right: $space;
		}

		&pv {
			padding-top: $space;
			padding-bottom: $space;
		}

		/* surrounding */
		&p-1 { padding: $space-1; }

		&p-2 { padding: $space-2; }

		&p-3 { padding: $space-3; }

		&p-4 { padding: $space-4; }

		&p-5 { padding: $space-5; }

		&p-6 { padding: $space-6; }

		&p-7 { padding: $space-7; }

		&p-8 { padding: $space-8; }

		/* horizontal */
		&ph-1 {
			padding-left: $space-1;
			padding-right: $space-1;
		}

		&ph-2 {
			padding-left: $space-2;
			padding-right: $space-2;
		}

		&ph-3 {
			padding-left: $space-3;
			padding-right: $space-3;
		}

		&ph-4 {
			padding-left: $space-4;
			padding-right: $space-4;
		}

		&ph-5 {
			padding-left: $space-5;
			padding-right: $space-5;
		}

		&ph-6 {
			padding-left: $space-6;
			padding-right: $space-6;
		}

		&ph-7 {
			padding-left: $space-7;
			padding-right: $space-7;
		}

		&ph-8 {
			padding-left: $space-8;
			padding-right: $space-8;
		}

		/* vertical */
		&pv-1 {
			padding-top: $space-1;
			padding-bottom: $space-1;
		}

		&pv-2 {
			padding-top: $space-2;
			padding-bottom: $space-2;
		}

		&pv-3 {
			padding-top: $space-3;
			padding-bottom: $space-3;
		}

		&pv-4 {
			padding-top: $space-4;
			padding-bottom: $space-4;
		}

		&pv-5 {
			padding-top: $space-5;
			padding-bottom: $space-5;
		}

		&pv-6 {
			padding-top: $space-6;
			padding-bottom: $space-6;
		}

		&pv-7 {
			padding-top: $space-7;
			padding-bottom: $space-7;
		}

		&pv-8 {
			padding-top: $space-8;
			padding-bottom: $space-8;
		}

		/* === margin === */

		/* none */
		&m-0 { margin: 0; }

		&mt-0 { margin-top: 0px; }

		&mr-0 { margin-right: 0px; }

		&mb-0 { margin-bottom: 0px; }

		&ml-0 { margin-left: 0px; }

		&mv-0 {
			margin-top: 0;
			margin-bottom: 0;
		}

		&mh-0 {
			margin-right: 0;
			margin-left: 0;
		}

		/* dynamic */
		&m { margin: $space; }

		&mt { margin-top: $space; }

		&mr { margin-right: $space; }

		&mb { margin-bottom: $space; }

		&ml { margin-left: $space; }

		&mv {
			margin-top: $space;
			margin-bottom: $space;
		}

		&mh {
			margin-right: $space;
			margin-left: $space;
		}

		/* top */
		&mt-1 { margin-top: $space-1; }

		&mt-2 { margin-top: $space-2; }

		&mt-3 { margin-top: $space-3; }

		&mt-4 { margin-top: $space-4; }

		&mt-5 { margin-top: $space-5; }

		&mt-6 { margin-top: $space-6; }

		&mt-7 { margin-top: $space-7; }

		&mt-8 { margin-top: $space-8; }

		/* right */
		&mr-1 { margin-right: $space-1; }

		&mr-2 { margin-right: $space-2; }

		&mr-3 { margin-right: $space-3; }

		&mr-4 { margin-right: $space-4; }

		&mr-5 { margin-right: $space-5; }

		&mr-6 { margin-right: $space-6; }

		&mr-7 { margin-right: $space-7; }

		&mr-8 { margin-right: $space-8; }

		/* bottom */
		&mb-1 { margin-bottom: $space-1; }

		&mb-2 { margin-bottom: $space-2; }

		&mb-3 { margin-bottom: $space-3; }

		&mb-4 { margin-bottom: $space-4; }

		&mb-5 { margin-bottom: $space-5; }

		&mb-6 { margin-bottom: $space-6; }

		&mb-7 { margin-bottom: $space-7; }

		&mb-8 { margin-bottom: $space-8; }

		/* left */
		&ml-1 { margin-left: $space-1; }

		&ml-2 { margin-left: $space-2; }

		&ml-3 { margin-left: $space-3; }

		&ml-4 { margin-left: $space-4; }

		&ml-5 { margin-left: $space-5; }

		&ml-6 { margin-left: $space-6; }

		&ml-7 { margin-left: $space-7; }

		&ml-8 { margin-left: $space-8; }
	}
}