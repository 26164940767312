/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Site Header
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

/* ===== VARIABLES ===== */

// See settings for more

$default-nav-bg: transparent;
$default-nav-color: $heading-color;
$default-nav-hover: $link-color;

$drawer-nav-bg: $white;
$drawer-nav-color: $heading-color;

$nav-time: 240ms;



/* ===== LAYOUT ===== */

.site-header {
	position: absolute;
	top: 0;
	width: 100vw;
	z-index: 99;

	.top-header {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 2;
		padding: 0.5rem 1rem;
		border-bottom: 1px solid #a89e8f;
	}

    .inner-header {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 2;
        left: 50%;
        transform: translateX(-50%);
		background-color: $default-nav-bg;
		padding: 1rem;
        color: $default-nav-color;
	}

	.header-nav {
		flex-wrap: nowrap;
	}

	.drawer-nav {
		z-index: 1;
	}

	@include below(tablet) {
		.inner-header {
			width: 100%;
		}
		.static-nav {
			display: none;
		}
	}

	@include above(tablet) {
		.nav-button {
			display: none;
		}
	}
}

/* ===== COMPONENTS ===== */

/* === LOGO === */
/*
| The logo height is how the header's whole size is determined, width will be automatically set.
*/

#logo {
	display: flex;
	flex-direction: column;
    flex-shrink: 0;
	position: relative;
	padding: 0 20px;
	margin-bottom: 0.5rem;
	color: $default-nav-color;

	svg, img {
		fill: currentColor;
		height: inherit;
	}

	// ACTIVE STATES
	.nav-open &,
	.nav-opening & {
		transition: color $nav-time ease-in-out;
		color: $drawer-nav-color;
	}

	.nav-closed &,
	.nav-closing & {
		transition: color $nav-time ease-in-out;
	}

	.logo-bottom-text {
		position: absolute;
		bottom: 0px;
		width: 87%;
		white-space: nowrap;
		letter-spacing: 2px;
		text-transform: uppercase;
		text-align: center;
		font-weight: bold;
		font-size: 1rem;
	}

	@include below( mobile ) {
		height: $mb_logo_ht;

		.logo-bottom-text { font-size: 0.7rem; }
	}

	@include between( mobile, tablet ) {
		height: $tb_logo_ht;

		.logo-bottom-text { font-size: 0.8rem; }
	}

	@include above( tablet ) {
		height: $dt_logo_ht;

		.logo-bottom-text { font-size: 14px; }
	}
}

/* ===== CONTACT LINKS ===== */

.contact-meta {
	display: flex;

	&__link {
		display: flex;
		align-items: center;
		letter-spacing: 3px;
		text-transform: uppercase;
		font-size: 0.7rem;
		font-family: $copy-font;
		color: $text-color;

		& + & {
			&::before {
				padding: 0 0.8rem;
				content: '\00B7';
			}
		}
	}

	&__icon {
		margin-right: 1rem;
		width: 0.7rem;
		color: $accent-alt;
	}

	&__text {
		display: inline-block;
	}

	@include below( tablet ) {
		width: 100%;
		height: 2rem;
		text-align: center;

		&__link {
			font-size: 0.9rem;
		}

		& > &__link {
			justify-content: center;
			line-height: 1.6;
		}

		& > &__link + &__link::before {
			display: none;
		}
	}
}

/* ===== STATIC NAV ===== */
.nav.main-left,
.nav.main-right {
	display: flex;
	position: relative;

	a {
		font-weight: bold;
		color: $forest;
		transition: color 300ms ease-in-out;

		&:hover,
		&:active {
			color: $default-nav-hover;
		}
	}

	a.hide { display: none; }
}

/* ===== DRAWER NAV ===== */

.drawer-nav {
	display: flex;
	flex-flow: column;
	position: fixed;
	top: 0;
	left: unset;
	bottom: 0;
	right: calc(100% - 100vw  + 1px);
	transform-origin: left top;
	margin-right: calc(50vw - 50%);
	width: calc(100vw + 2px);
	height: 100vh;
	background-color: $drawer-nav-bg;
	padding: 0;
	line-height: 1;
	text-align: center;
	font-size: 2rem;

	// These before and afters are for fading the nav items
	// top and bottom as you scroll through them.
	&:before,
	&:after {
		position: absolute;
		z-index: 9;
		left: 0;
		width: 100%;
		height: 1em;
		content: '';
	}

	&:before {
		top: 0;
		background: linear-gradient(rgba($drawer-nav-bg, 1) 0%,
									rgba($drawer-nav-bg, 0) 100%);
	}

	&:after {
		bottom: 0;
		background: linear-gradient(rgba($drawer-nav-bg, 0) 0%,
									rgba($drawer-nav-bg, 1) 100%);
	}

	.inner-nav {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0 auto;
		width: 100%;
		max-width: $max-width;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 1em 0 0;

		// padding bottom isn't defining the bottom scroll position,
		// so I am using this ::after to create some space for the last-child
		&::after {
			display: block;
			opacity: 0;
			min-height: 1em;
			content: ' ';
		}

		> * {
			margin: 0.5rem 0;

			&:first-child { margin-top: 0; }

			&:last-child { margin-bottom: 0; }
		}
	}

	a {
		display: inline-block;
		margin: 0 auto;
		padding: 0.8rem $header-pad;
		text-transform: capitalize;
		font-family: $heading-font;
		font-weight: normal;
		color: $drawer-nav-color;
		transition: color 200ms ease-in-out;

		&:hover,
		&:active {
			color: $default-nav-hover;
		}
	}

	/* === NAVIGATION LINKS === */
	nav.site-header-nav {
		display: flex;
		flex-flow: column;

		a {
			font-size: inherit;
		}
	}

	// ANIMATIONS
	@keyframes open_drawer {
		from {
			opacity: 0;
			transform: scale(1.05);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes close_drawer {
		from {
			opacity: 1;
			transform: scale(1);
		}
		to {
			opacity: 0;
			transform: scale(1.05);
		}
	}

	// ACTIVE STATES
	.nav-closed & {
		visibility: hidden;
    }

    .nav-opening & {
		animation: open_drawer $nav-time ease-in-out forwards;
	}

	.nav-opened & {
        visibility: visible;
    }

    .nav-closing & {
		animation: close_drawer $nav-time ease-in-out forwards;
	}

	@include below( mobile ) {
		padding-top: $mb_header_ht;

		&:before { top: $mb_header_ht; }
	}

	@include between( mobile, tablet ) {
		padding-top: $tb_header_ht;

		&:before { top: $tb_header_ht; }
	}

	@include above( tablet ) {
		padding-top: $dt_header_ht;

		&:before { top: $dt_header_ht; }
	}
}

/* === NAV BUTTON === */
.nav-button {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	//
	position: absolute;
	z-index: 99;
	right: 2em;
	//
	outline: none;
	border: none;
	border-radius: 50%;
	width: 4em;
	height: 4em;
	background-color: transparent;
	padding: 2em;
	// font size canges the whole size
	font-size: 8px;
	color: $accent-alt;
	transition: color 250ms ease;
	cursor: pointer;

	span {
		background-color: currentColor;
	}

    .dots {
		display: flex;
		justify-content: space-evenly;
        align-items: center;
        width: 50%;
        min-width: 40px;
        overflow: hidden;
		cursor: pointer;

        span {
			display: inline-block;
			border-radius: 50%;
            width: 5px;
            height: 5px;
        }
    }

    .close-x {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
        width: 3em;
        height: 3em;
		cursor: pointer;

        span {
			display: block;
			position: absolute;
			border-radius: 2px;
            width: 0;
            height: 4px;

            &:first-child {
                transform: rotate(-45deg);
            }
            &:last-child {
                transform: rotate(45deg);
            }
        }
	}

	// HOVER STATES
	&:hover,
    &:focus {
        color: $accent;
    }

	// ACTIVE STATES
	.nav-open &,
	.nav-opening & {
		background-color: transparent;

        .dots {
            width: 0;
            min-width: 0;
            transition: all $nav-time ease;
        }
        .close-x span {
			width: 2rem;
			transition: all $nav-time ease;
		}
	}

	.nav-closed &,
	.nav-closing & {
        background-color: transparent;

        .dots {
            transition: all $nav-time ease;
        }
        .close-x span {
			transition: all $nav-time ease;
		}
	}

	/* ABOVE MOBILE */
	@include above(mobile) {
		font-size: 10px;
	}

	/* ABOVE TABLET */
	@include above(tablet) {
		font-size: 12px;
	}
}