/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Masonry
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.masonry {
	columns: 3 10rem;
	column-gap: 1rem;
	margin-bottom: -1rem;

	&__brick {
		display: inline-block;
		margin: 0 0 1rem;
		width: 100%;
	}
}