/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| CORE: Media
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

figure {
	margin: 0;
}

video, img, svg {
	display: block;
	max-width: 100%;
	height: auto;
}

svg {
	width: 100%;
	height: 100%;
}

.svg-fill {
	fill: currentColor;
}

.svg-stroke {
	fill: none;
	stroke: currentColor;
	stroke-width: 5;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}

.no-linecap {
	stroke-linecap: unset;
}

.no-linejoin {
	stroke-linejoin: unset;
}

/* ===== Inner Border ===== */

.inner-border {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: $space;
		border: 2px solid #ececec;
		content: '';
	}

	@include below( mobile ) {
		&::after {
			margin: 1rem;
			border-width: 1px;
		}
	}

	@include between( mobile, tablet ) {
		&::after {
			margin: 1.2rem;
		}
	}
}

.border {
	border: 2px solid #ececec;
}

.outer-border {
	position: relative;

	&::before {
		position: absolute;
		top: -1em;
		right: -1em;
		left: -1em;
		bottom: -1em;
		border: 1px solid #ececec;
		font-size: 0.8rem;
		content: '';
	}
}
@include below(tablet) {
	.mb\:outer-border\:none {
		&::before {
			display: none;
		}
	}
}
@include above(tablet) {
	.dt\:outer-border\:none {
		&::before {
			display: none;
		}
	}
}

/* ===== Double Image ===== */

.double-image {
	display: flex;
	flex-flow: row nowrap;

	.bg-img {
		max-width: 50%;
	}
}